// Services
import createApiService from "@/services";

export const leadApi = async (leadData: { agentId: string; email: string; message: string[] }) => {
  const api = createApiService({
    baseUrl: 'https://botric-stapi.pdrp.in/api/v1/',
  });

  try {
    return await api.post("/leads", leadData);
  } catch (error) {
    console.error("Error in creating lead:", error);
    throw error;
  }
};
