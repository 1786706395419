import React from 'react';
import styled from '@emotion/styled';

const ChatHeader = styled.div`
  background-color: ${props => props.theme?.colors?.primary};
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1 / 1;
  height: fit-content;

  @media (max-width: 768px) {
    padding: 10px;           
    height: fit-content;           
    font-size: 0.9rem;
  }
`;

export default ChatHeader;