type RequestInit = globalThis.RequestInit;

interface ApiConfig extends Omit<RequestInit, 'body'> {
  baseUrl: string;
};

interface RequestOptions extends Omit<RequestInit, 'body'> {
  data?: unknown;
  isSSE?: boolean;
};

const createApiService = (config: ApiConfig) => {
  const request = async <T>(
    endpoint: string,
    { data, isSSE = false, ...customConfig }: RequestOptions = {}
  ): Promise<T | Response> => {
    const headers = {
      'Content-Type': 'application/json',
      ...config.headers,
      ...customConfig.headers,
    };

    const requestConfig: RequestInit = {
      ...config,
      ...customConfig,
      headers,
    };

    if (data) {
      requestConfig.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(`${config.baseUrl}${endpoint}`, requestConfig);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        const { status } = response ?? {};
        throw { status, ...errorData };
      }

      if (isSSE) {
        return response as unknown as T;
      }

      return await response.json();
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };

  return {
    get: <T>(endpoint: string, options?: Omit<RequestOptions, 'data'>) =>
      request<T>(endpoint, { ...options, method: 'GET' }),
    post: <T>(endpoint: string, data: unknown, options?: RequestOptions) =>
      request<T>(endpoint, { ...options, method: 'POST', data }),
    put: <T>(endpoint: string, data: unknown, options?: RequestOptions) =>
      request<T>(endpoint, { ...options, method: 'PUT', data }),
    delete: <T>(endpoint: string, options?: Omit<RequestOptions, 'data'>) =>
      request<T>(endpoint, { ...options, method: 'DELETE' }),
  };
};

export default createApiService;