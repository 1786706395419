import * as React from "react";
import { FC, useState, useRef, useLayoutEffect, useEffect } from "react";
// STYLING & THEMES
import { ThemeProvider } from "@emotion/react";
import theme from "@/theme";
import styled from "@emotion/styled";
// COMPONENTS
import ChatbotContainer from "@/components/chat/container";
import ChatHeader from "@/components/chat/header";
import ChatBody from "@/components/chat/body";
import ChatBubble from "@/components/chat/bubble";
import InitialChat from "./components/chat/initial";
import ToggleButton from "@/components/chat/button/toggle";
import SendButton from "@/components/chat/button/send";
import Input from "@/components/chat/input";
import InputArea from "@/components/chat/input/area";
import InputWrapper from "./components/chat/input/container";
import Timestamp from "./components/chat/timestamp";
import ReasoningLoader from "./components/chat/thinking-loader";
import {
  PopupContainer,
  CloseButton,
  PopupMessage,
} from "@/components/popup/popup";
import FormattedMessage from "./components/fomatter";
import { FollowUpQuestions } from "./components/chat/followup-question";
import { Tooltip } from "react-tooltip";
// HOOKS
import useChat from "@/hooks/use-chat";
import useChatbotData from "./hooks/use-getbot";
// CONSTANTS
import { BOT } from "@/constants";
// LOGOS
import SendLogo from "@/assets/send.svg";
import BotLogo from "@/assets/bot.gif";
import Branding from "@/assets/Powered.png";
import ChatBotShadowDom from "@/chatbotShadowDom";
import createCache from "@emotion/cache";
import { leadApi } from "./services/lead";

// TYPES
interface ChatbotProps {
  id: string;
}

const apiUrl = "https://staging-api.botric.ai/api/v1/chatbot";
// const apiUrl = "http://localhost:8000/api/v1/chatbot"

const Text = styled.p`
  font-size: 20px;
  line-height: 1.5;
  max-width: 300px;
  text-align: center;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme?.colors?.bgColor};
  margin-left: 8px;

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PoweredBy = styled.a`
  margin-top: 0.5rem;
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LogoImg = styled.img`
  width: 60px;

  @media (max-width: 768px) {
    width: 50px;
  }
`;

const Chatbot: FC<ChatbotProps> = ({ id }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [showEmailPrompt, setShowEmailPrompt] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  const chatBodyRef = useRef<HTMLDivElement | null>(null);

  const { chatbotData } = useChatbotData(apiUrl, id);
  const {
    messages,
    sendMessage,
    followUpQuestions,
    isLoading,
    isError,
    error,
  } = useChat(apiUrl, id ?? "");

  useEffect(() => {
    if (!isOpen && !sessionStorage.getItem("popupClosed")) {
      const timer = setTimeout(() => {
          setShowPopup(true);
          setIsPopupVisible(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setShowPopup(false);
      setIsPopupVisible(false);
    }
  }, [isOpen]);

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
      setInput("");
    }
  };

  

  const handleOpen = (state = true) => () => {
    if (!state) {
      const promptShown = sessionStorage.getItem("popupShown");
      if (promptShown) {
          setIsOpen(state); 
          return;
      }

      const emailSubmitted = sessionStorage.getItem("emailSubmitted");
      const dontShowAgain = localStorage.getItem("dontShowEmailPrompt");
  
      if (chatbotData?.settings?.leadGeneration?.enabled && !emailSubmitted && !dontShowAgain) {
        setShowEmailPrompt(true);
        return; 
      }
    }
    setIsOpen(state);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    if (!email.trim()) return;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    try {
      if (chatbotData) {
        await leadApi({ 
          agentId: chatbotData._id, 
          email, 
          message: messages.map((msg) => msg.text)
        });
      }

      sessionStorage.setItem("emailSubmitted", "true");
      setShowEmailPrompt(false);
      setIsOpen(false);
    } catch (error) {
      console.error("Failed to submit lead:", error);
    }
  };

  const handleEmailPopupClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("dontShowEmailPrompt", "true");
    }
    setShowEmailPrompt(false);
    sessionStorage.setItem("popupShown", "true");
    setIsOpen(false);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setTimeout(() => {
      setShowPopup(false);
      sessionStorage.setItem("popupClosed", "true");
    }, 300);
  };

  useLayoutEffect(() => {
    if (chatBodyRef?.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current?.scrollHeight;
    }
  }, [messages, error, isOpen]);

  const handleFollowUp = (question: string) => {
    sendMessage(question);
  };

  const formatTimestamp = (date: string | number) => {
    const d = new Date(date);
    let hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    return `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  return (
    <ChatBotShadowDom>
      <ThemeProvider theme={chatbotData?.design?.theme || theme}>
        <div
          style={{
            position: "fixed",
            bottom: "32px",
            right: "32px",
            zIndex: 2147483646,
            textAlign: "left",
          }}
          ref={containerRef}
        >
          {!isOpen && showPopup && (
            <PopupContainer isVisible={isPopupVisible}>
              <CloseButton onClick={handlePopupClose}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 3.5L3.5 12.5M3.5 3.5L12.5 12.5"
                    stroke="#666666"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </CloseButton>
              <PopupMessage>Got questions? Let's chat!</PopupMessage>
            </PopupContainer>
          )}
          <ChatbotContainer isOpen={isOpen}>
            {isOpen ? (
              <>
                <ChatHeader>
                  <Title>
                    {chatbotData?.design?.icon && (
                      <img
                        src={chatbotData.design.icon}
                        alt="Chatbot Icon"
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "18px",
                        }}
                      />
                    )}
                    <Text>
                      {chatbotData?.name
                        ? chatbotData.name.charAt(0).toUpperCase() +
                          chatbotData.name.slice(1).toLowerCase()
                        : ""}{" "}
                      Assist AI
                    </Text>
                  </Title>
                  <ToggleButton
                    data-tooltip-id="close-tooltip"
                    onClick={handleOpen(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      width="25px"
                      height="25px"
                    >
                      <path
                        style={{
                          fill: chatbotData?.design?.theme?.colors?.bgColor,
                        }}
                        d="M 7.5 5 C 7.244125 5 6.9879687 5.0974687 6.7929688 5.2929688 L 5.2929688 6.7929688 C 4.9019687 7.1839688 4.9019687 7.8170313 5.2929688 8.2070312 L 12.585938 15.5 L 5.2929688 22.792969 C 4.9019687 23.183969 4.9019687 23.817031 5.2929688 24.207031 L 6.7929688 25.707031 C 7.1839688 26.098031 7.8170313 26.098031 8.2070312 25.707031 L 15.5 18.414062 L 22.792969 25.707031 C 23.182969 26.098031 23.817031 26.098031 24.207031 25.707031 L 25.707031 24.207031 C 26.098031 23.816031 26.098031 23.182969 25.707031 22.792969 L 18.414062 15.5 L 25.707031 8.2070312 C 26.098031 7.8170312 26.098031 7.1829688 25.707031 6.7929688 L 24.207031 5.2929688 C 23.816031 4.9019687 23.182969 4.9019687 22.792969 5.2929688 L 15.5 12.585938 L 8.2070312 5.2929688 C 8.0115312 5.0974687 7.755875 5 7.5 5 z"
                      />
                    </svg>
                  </ToggleButton>
                  <Tooltip
                    id="close-tooltip"
                    content="Close Chat"
                    place="bottom"
                  />
                </ChatHeader>
                <ChatBody ref={chatBodyRef}>
                  {showEmailPrompt && chatbotData?.settings?.leadGeneration?.enabled && (
                    <><div style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      zIndex: 2147483646,
                      borderRadius: "inherit"
                    }} />
                    <div style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      padding: "16px",
                      width: "80%",
                      maxWidth: "350px",
                      textAlign: "center",
                      background: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      zIndex: 2147483647
                    }}>
                        <h3 style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "600", color: "#333" }}>
                          {chatbotData?.settings?.leadGeneration?.fields?.header}
                        </h3>
                        <p style={{ fontSize: "14px", color: "#666", marginBottom: "15px" }}>
                          {chatbotData?.settings?.leadGeneration?.fields?.subHeader}
                        </p>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder={chatbotData?.settings?.leadGeneration?.fields?.inputPlaceHolder}
                          style={{
                            width: "80%",
                            padding: "8px",
                            marginBottom: "10px",
                            borderRadius: "6px",
                            border: "1px solid #ccc",
                            outline: "none",
                            fontSize: "14px",
                            transition: "border-color 0.2s",
                          }} />
                          {emailError && <p style={{ color: "red", fontSize: "12px", marginTop: "-8px" }}>{emailError}</p>}
                        <div style={{ marginTop: "15px", display: "flex", gap: "10px", justifyContent: "center" }}>
                          <button
                            onClick={handleEmailSubmit}
                            style={{
                              padding: "10px 15px",
                              background: `${chatbotData?.design?.theme?.colors?.primary}`,
                              color: `${chatbotData?.design?.theme?.colors?.bgColor}`,
                              borderRadius: "6px",
                              border: "none",
                              fontSize: "14px",
                              cursor: "pointer",
                              transition: "background 0.3s",
                            }}
                          >
                            Submit
                          </button>
                          <button
                            onClick={handleEmailPopupClose}
                            style={{
                              padding: "10px 15px",
                              background: "#ccc",
                              color: "#000",
                              borderRadius: "6px",
                              border: "none",
                              fontSize: "14px",
                              cursor: "pointer",
                              transition: "background 0.3s",
                            }}
                          >
                            Close
                          </button>
                        </div>
                        <div style={{ marginTop: "15px", display: "flex", alignItems: "center", gap: "6px", justifyContent: "center" }}>
                          <input
                            type="checkbox"
                            checked={dontShowAgain}
                            onChange={() => setDontShowAgain(!dontShowAgain)}
                            style={{ cursor: "pointer" }} />
                          <label style={{ fontSize: "12px", color: "#666", cursor: "pointer" }}>
                            Don't show again
                          </label>
                        </div>
                      </div></>
                  )}
                  { messages.length === 0 && !isLoading && !isError ? (
                    <InitialChat
                      message={chatbotData?.settings?.welcomeMessage}
                      questions={chatbotData?.settings?.suggestedQuestions}
                      onQuestionClick={handleFollowUp}
                      color={chatbotData?.design?.theme?.colors?.primary}
                    />
                  ) : (
                    <>
                      {messages.map(
                        (
                          { id = "", text = "", sender = BOT, timestamp = "" },
                          index: number
                        ) => {
                          const isLastBotMessage =
                            sender === BOT &&
                            index === messages.length - 1 &&
                            isLoading &&
                            text.trim() === "";

                          const showTimestamp = !(
                            sender === BOT &&
                            index === messages.length - 1 &&
                            isLoading &&
                            text.trim() === ""
                          );
                          return (
                            <ChatBubble
                              key={id}
                              sender={sender}
                              timestamp={formatTimestamp(timestamp)}
                            >
                              {sender === BOT ? (
                                <>
                                  <FormattedMessage
                                    content={
                                      isError && sender === BOT
                                        ? error?.message ?? "Unknown Error"
                                        : text
                                    }
                                  />
                                  {isLastBotMessage && <ReasoningLoader />}
                                  {/* {index === messages.length - 1 && isLoading && (
                                  <LoadingDots />
                                )} */}
                                </>
                              ) : (
                                text
                              )}
                              {showTimestamp && (
                                <Timestamp>
                                  {formatTimestamp(timestamp)}
                                </Timestamp>
                              )}
                            </ChatBubble>
                          );
                        }
                      )}
                      {followUpQuestions?.length > 0 && (
                        <FollowUpQuestions
                          questions={followUpQuestions}
                          onSelect={handleFollowUp}
                        />
                      )}
                    </>
                  )}
                </ChatBody>
                <InputArea>
                  <InputWrapper>
                    <Input
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyUp={(e) =>
                        !isLoading && e.key === "Enter" && handleSend()
                      }
                      placeholder={chatbotData?.settings?.placeholderText}
                    />
                    <SendButton
                      data-tooltip-id="send-tooltip"
                      onClick={handleSend}
                      disabled={isLoading || input === ""}
                    >
                      <img src={SendLogo} alt="Send" />
                    </SendButton>
                    <Tooltip
                      id="send-tooltip"
                      content="Send Message"
                      place="top"
                    />
                  </InputWrapper>
                  <PoweredBy href="https://botric.ai" target="_blank">
                    <img src={Branding} alt="Botric" width="125" />
                  </PoweredBy>
                </InputArea>
              </>
            ) : (
              <ToggleButton onClick={handleOpen(true)}>
                <LogoImg src={BotLogo} alt="Start Chat" />
              </ToggleButton>
            )}
          </ChatbotContainer>
        </div>
      </ThemeProvider>
    </ChatBotShadowDom>
  );
};

export default Chatbot;

