import { useState, useEffect } from "react";
import { botApi } from "@/services/bot";

// TYPES
interface ChatbotData {
  design: {
    theme: {
      colors: {
        primary: string;
        secondary: string;
        bgColor: string;
        foreground: string;
        header: string;
      };
    };
    icon: string;
    font: string;
    textColor: string;
  };
  settings: {
    welcomeMessage: string;
    placeholderText: string;
    suggestedQuestions: string[];
    leadGeneration: {
      enabled: boolean;
      fields: {
        header: string;
        subHeader: string;
        inputPlaceHolder: string;
      };
    }
    language: string;
  };
  name: string;
  _id: string;
}

interface ApiResponse {
  success: boolean;
  chatbot: ChatbotData;
}

const useChatbotData = (apiUrl: string, id: string) => {
  const [chatbotData, setChatbotData] = useState<ChatbotData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchChatbotDetails = async () => {
      setIsLoading(true);
      setIsError(false);
      setError(null);

      try {
        const data = await botApi(apiUrl, id) as ApiResponse; 
        if (data.success) {
          setChatbotData(data.chatbot);
        } else {
          setIsError(true);
          setError("Failed to fetch chatbot data.");
        }
      } catch (err) {
        setIsError(true);
        setError((err as Error).message || "Unknown error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatbotDetails();
  }, [id]);

  return { chatbotData, isLoading, isError, error };
};

export default useChatbotData;
