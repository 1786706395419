import React from 'react';
import styled from '@emotion/styled';

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #e6e6e6;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1441px) {
    padding: 1.25rem;
  }

  @media (max-width: 1024px) {
    padding: 0.875rem;
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 0.625rem;
  }
`;


export default InputArea;
