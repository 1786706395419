import React from 'react';
import styled from '@emotion/styled';

const FollowUpWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f7f9fc;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

const FollowUpTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1rem;
`;

const FollowUpQuestion = styled.button`
  display: block;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  padding: 0.75rem 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  color: #2d3748;
  border-radius: 0.75rem;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.bgColor};
  }
`;

export const FollowUpQuestions: React.FC<{ questions: string[]; onSelect: (q: string) => void }> = ({
  questions,
  onSelect,
}) => {

  if (!questions || questions.length === 0) {
    return (
      <FollowUpWrapper>
        <FollowUpTitle>No follow-up questions available</FollowUpTitle>
      </FollowUpWrapper>
    );
  }

  return (
    <FollowUpWrapper>
      <FollowUpTitle>Want to know more?</FollowUpTitle>
      {questions.map((q, index) => (
        <FollowUpQuestion key={index} onClick={() => onSelect(q)}>
          {q}
        </FollowUpQuestion>
      ))}
    </FollowUpWrapper>
  );
};
