import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const shimmerAnimation = keyframes`
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: 0% center;
  }
`;

const LoaderText = styled.span`
  display: inline-block;
  font-size: 1rem;
  font-weight: 300; 
  background: linear-gradient(
    90deg,
    #333 30%,
    #fff 50%,
    #333 70%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${shimmerAnimation} 2s infinite;
`;

const ReasoningLoader = () => {
  return <LoaderText>Thinking...</LoaderText>;
};

export default ReasoningLoader;
