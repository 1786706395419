import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

export const PopupContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 80px;
  right: 0;
  background: white;
  padding: 16px;
  border-radius: 14px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  width: 250px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: #1a1a1a;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.3s ease forwards;
  z-index: 1000;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

export const PopupMessage = styled.p`
  margin: 0;
  padding-right: 20px;
`; 