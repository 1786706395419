import styled from "@emotion/styled";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 30px;
  padding: 0.2rem;
  width: 100%;
  position: relative;
`;

export default InputWrapper;