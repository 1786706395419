import React from "react";
import styled from "@emotion/styled";

const Input = styled.input`
  width: 100%;
  resize: none;
  margin: 0;
  border: none;
  background: #f5f5f5;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.25;
  outline: none;
  box-sizing: border-box;
  padding: 10px 13px 10px 13px;
  border-radius: 30px;

  flex-grow: 1;
  min-height: 1.25rem; /* Single line height */
  max-height: 3.75rem; /* Three lines height */
  overflow-y: auto; /* Enables scrolling after three lines */
  white-space: pre-wrap;

  @media (min-width: 1440px) {
    font-size: 1.125rem;
    min-height: 1.5rem;
    max-height: 4rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.9375rem;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 8px 14px 8px 11px;
    min-height: 1.125rem;
    max-height: 3.5rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8125rem;
    padding: 7px 14px 7px 10px;
    min-height: 1rem;
    max-height: 3.25rem;
  }

  &:focus {
    outline: none;
  }
`;

export default Input;
