import React from 'react';
import styled from '@emotion/styled';

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  justify-self: center;
  grid-row: span 5 / span 5;
`;

export default ToggleButton;
