// HOOKS
import { useState, useCallback } from "react";
// SERVICES
import { chatApi } from "@/services/chat";
// CONSTANTS
import { USER, BOT } from "@/constants";
// TYPES
interface Message {
  id: string;
  text: string;
  sender: typeof USER | typeof BOT;
  timestamp: number;
};

interface ChatState {
  messages: Message[];
  followUpQuestions: string[];
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
};

const useChat = (apiUrl: string, botId: string) => {
  const [state, setState] = useState<ChatState>({
    messages: [],
    followUpQuestions: [],
    isLoading: false,
    isError: false,
    error: null,
  });

  const { messages = [] } = state ?? {};

  const sendMessage = useCallback(
    async (input: string) => {
      if (input.trim()) {
        const userMessageId = Date.now().toString();
        const botMessageId = (Date.now() + 1).toString();
        const timestamp = Date.now();

        setState({
          messages: [
            ...messages,
            { id: userMessageId, text: input, sender: USER, timestamp },
            { id: botMessageId, text: "", sender: BOT, timestamp },
          ],
          followUpQuestions: [],
          isLoading: true,
          isError: false,
          error: null,
        });
        
        try {
          const response = await chatApi(apiUrl, input, botId);
          const reader = response.body?.getReader();

          if (!reader) throw new Error("ReadableStream not supported");

          const decoder = new TextDecoder();
          let fullResponse = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split("\n");

            for (const line of lines) {
              if (line.startsWith("data: ")) {
                const jsonData = line.slice(6);
                try {
                  const { text = "", error = "", followUpQuestions = [] } = JSON.parse(jsonData) ?? {};
                                    
                  if (followUpQuestions.length > 0) {
                    const sanitizedQuestions = followUpQuestions.map((q: string) =>
                      q.replace(/^\d+\.\s*/, "").trim()
                    ); 
          
                    setState((prevState) => ({
                      ...prevState,
                      followUpQuestions: sanitizedQuestions,
                    }));
                  }

                  if (text === "[DONE]") {
                    setState((prevState) => ({
                      ...prevState,
                      isLoading: false,
                      messages: prevState?.messages.map((msg) =>
                        msg.id === botMessageId
                          ? { ...msg, text: fullResponse }
                          : msg
                      ),
                    }));
                    return;
                  }

                  if (error) throw new Error(error);

                  fullResponse += text;

                  setState((prevState) => ({
                    ...prevState,
                    messages: prevState?.messages.map((msg) =>
                      msg.id === botMessageId
                        ? { ...msg, text: fullResponse }
                        : msg
                    ),
                  }));
                } catch (e) {
                  console.error("Error parsing SSE data:", e);
                  throw e;
                }
              }
            }
          }

          setState((prevState) => ({
            ...prevState,
            messages: prevState.messages.map((msg) =>
              msg.id === botMessageId ? { ...msg, text: fullResponse } : msg
            ),
            isLoading: false,
          }));
        } catch (error) {
          console.error("Error sending message:", error);
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isError: true,
            error: error instanceof Error ? error : new Error("Unknown error"),
          }));
        }
      }
    },
    [messages, botId]
  );

  return { ...state, sendMessage };
};

export default useChat;

