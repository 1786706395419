// UTILS
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkBreaks from 'remark-breaks';
// COMPONENTS
import MemoizedMarkdown from '@/components/fomatter/markdown';

const markdownStyles = css`
  word-break: break-word;
  p {
    line-height: 1.625;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MarkdownWrapper = styled.div`
  ${markdownStyles}
`;

const ListItem = styled.li`
  max-height: fit-content;
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  max-width: 42rem;
`;

const UnorderedList = styled.ul`
  list-style-type: disc;
  max-height: fit-content;
  white-space: normal;
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  max-height: fit-content;
  white-space: normal;
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

const Link = styled.a`
  color: #196b8c;
  font-weight: medium;
  &:hover {
    text-decoration: underline;
  }
`;

interface FormattedMessageProps {
  content?: string;
}

const FormattedMessage: React.FC<FormattedMessageProps> = ({ content = '' }) => {
  const sanitizedContent = content?.replace(/\\n/g, '\n').replace(/\\([^\\]+)\\/g, '"$1"') ?? "";

  return (
    <MarkdownWrapper>
      <MemoizedMarkdown
        remarkPlugins={[remarkGfm, remarkMath, remarkBreaks]}
        components={{
          p: ({ children }) => <p>{children}</p>,
          li: ({ children }) => <ListItem>{children}</ListItem>,
          ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
          ol: ({ children }) => <OrderedList>{children}</OrderedList>,
          a: ({ children, ...props }) => (
            <Link target="_blank" rel="noopener noreferrer" {...props}>
              {children}
            </Link>
          ),
        }}
      >
        {sanitizedContent}
      </MemoizedMarkdown>
    </MarkdownWrapper>
  );
};

export default FormattedMessage;