import React from "react";
import styled from "@emotion/styled";

const ChatbotContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: ${(props) => (props.isOpen ? "25svw" : "4rem")};
  height: ${(props) => (props.isOpen ? "90svh" : "4rem")};
  background-color: #fff;
  border-radius: ${(props) => (props.isOpen ? "18px" : "50%")};
  box-shadow: ${(props) =>
    props.isOpen
      ? "0 4px 20px rgba(0, 0, 0, 0.2), 0 8px 30px rgba(0, 0, 0, 0.15), 0 2px 10px rgba(0, 0, 0, 0.1)"
      : `0 0 1px ${props.theme.colors.primary}, 0 0 4px ${props.theme.colors.primary}, 0 0 10px rgba(255, 255, 255, 0.1)`};
  transition: all 0.3s ease;
  overflow: hidden;
  display: grid;
  /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
  grid-template-rows: auto 1fr auto;
  gap: 0;
  place-content: ${(props) => (props.isOpen ? "normal" : "center")};
  z-index: 2147483646;

  @media (max-width: 1024px) {
    width: ${(props) => (props.isOpen ? "40svw" : "3rem")};
    height: ${(props) => (props.isOpen ? "90svh" : "3rem")};
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.isOpen ? "40svw" : "3rem")};
    height: ${(props) => (props.isOpen ? "90svh" : "3rem")};
  }

  @media (max-width: 500px) {
    width: ${(props) => (props.isOpen ? "75svw" : "3rem")};
    height: ${(props) => (props.isOpen ? "90svh" : "3rem")};
  }
`;

export default ChatbotContainer;
