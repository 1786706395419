// UTILS
import React from "react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
// CONSTANTS
import { RIGHT_TAIL_SVG, LEFT_TAIL_SVG, USER, BOT } from "@/constants";
// TYPES
interface ChatBubbleProps {
  sender: typeof USER | typeof BOT;
  timestamp: string;
}
// ANIMATIONS
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ChatBubble = styled.div<ChatBubbleProps>`
  width: fit-content;
  max-width: 90%;
  padding: 8px 12px;
  border-radius: 18px;
  margin-bottom: 10px;
  animation: ${fadeIn} 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  font-weight: 400;
  font-family: 'apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1441px) {
    max-width: 85%;
    font-size: 16px;
    padding: 12px 18px;
  }

  @media (max-width: 1024px) {
    max-width: 92%;
    font-size: 14px;
    padding: 10px 14px;
  }

  @media (max-width: 768px) {
    max-width: 94%;
    font-size: 14px;
    padding: 10px 12px;
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    max-width: 96%;
    font-size: 13px;
    padding: 8px 12px;
    margin-bottom: 8px;
  }

  ${(props) =>
    props.sender === USER
      ? css`
          background-color: ${props.theme.colors.primary};
          color: ${props.theme.colors.bgColor};
          align-self: flex-end;
          margin-left: auto;
          border-bottom-right-radius: 0;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: -10px;
            width: 10px;
            height: 20px;
            background-color: inherit;
            mask-image: url(${RIGHT_TAIL_SVG});
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: left bottom;
          }

          @media (max-width: 480px) {
            &::after {
              width: 8px;
              height: 16px;
              right: -8px;
            }
          }
        `
      : css`
          background-color: #f7f9fc;
          color: #222; 
          align-self: flex-start;
          margin-right: auto;
          border-bottom-left-radius: 0;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -10px;
            width: 10px;
            height: 20px;
            background-color: inherit;
            mask-image: url(${LEFT_TAIL_SVG});
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: right bottom;
          }

          @media (max-width: 480px) {
            &::after {
              width: 8px;
              height: 16px;
              left: -8px;
            }
          }
        `}
`;

export default ChatBubble;
