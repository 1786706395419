// SERVICES
import createApiService from "@/services";

export const chatApi = async (apiUrl: string,query: string, id: string)=>{
    const api = createApiService({
        baseUrl: `${apiUrl}/chat/${id}`,
    });
    try {
      return await api.post<Response>(
        "",
        { query },
        { isSSE: true }
      );
    } catch (error) {
      console.error("Error in chat function:", error);
      throw error;
    };
};
