import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

interface InitialChatProps {
  message: string | undefined;
  color?: string;
  questions?: string[];
  onQuestionClick?: (question: string) => void;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  animation: ${fadeInUp} 0.6s ease-out;

  @media (max-width: 1024px) {
    padding: 16px;
  }

  @media (max-width: 480px) {
    padding: 12px;
  }
`;

const Icon = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${bounce} 2s infinite ease-in-out;

  svg {
    width: 80px;
    height: 80px;
    display: block;
  }

  @media (max-width: 1024px) {
    margin-bottom: 16px;
    svg {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 12px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;

const Message = styled.p`
  font-size: 18px;
  line-height: 1.5;
  max-width: 320px;
  font-weight: 500;
  font-family: "apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: ${(props) => props.theme.colors.primary};
  animation: ${fadeInUp} 0.8s ease-out;

  @media (max-width: 1024px) {
    font-size: 16px;
    max-width: 280px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    max-width: 240px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 20px;
  width: 100%;
  max-width: 340px;

  @media (max-width: 1024px) {
    gap: 10px;
    margin-top: 16px;
    max-width: 300px;
  }

  @media (max-width: 480px) {
    gap: 8px;
    margin-top: 12px;
    max-width: 260px;
    grid-template-columns: 1fr;
  }
`;

const QuestionBox = styled.button`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: #333;
  padding: 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  transform: translateY(0);
  animation: ${fadeInUp} 1s ease-out;

  @media (max-width: 1024px) {
    padding: 10px;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 12px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.bgColor};
    transform: translateY(-4px) scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const InitialChat: React.FC<InitialChatProps> = ({ message, questions = [], onQuestionClick, color }) => {
  return (
    <Container>
      {/* Uncomment if using an icon */}
      {/* <Icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            style={{
              stroke: color,
              strokeWidth: 2,
              fill: "none",
            }}
            d="M21 11.5c.003 1.32-.305 2.622-.9 3.8-.706 1.412-1.79 2.6-3.133 3.43-1.342.83-2.889 1.27-4.467 1.27-1.32.004-2.622-.305-3.8-.9L3 21l1.9-5.7c-.595-1.178-.903-2.48-.9-3.8.001-1.578.44-3.125 1.27-4.467.831-1.343 2.018-2.427 3.43-3.133 1.178-.595 2.48-.903 3.8-.9h.5c2.084.115 4.053.995 5.529 2.47 1.475 1.477 2.355 3.446 2.471 5.53v.5z"
          />
        </svg>
      </Icon> */}
      <Message>{message}</Message>
      <Grid>
        {questions.map((question, index) => (
          <QuestionBox key={index} onClick={() => onQuestionClick?.(question)}>
            {question}
          </QuestionBox>
        ))}
      </Grid>
    </Container>
  );
};

export default InitialChat;
