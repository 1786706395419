// SVG paths
const RIGHT_TAIL_PATH = "m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0";
const LEFT_TAIL_PATH = "m 3 3 L 2 3 L 0 3 C 1 3 3 1 3 0";

// Base64 encoded SVGs for the tails (Not made svg files for better optimization)
export const RIGHT_TAIL_SVG = `data:image/svg+xml;base64,${btoa(`<svg width="3" height="3" xmlns="http://www.w3.org/2000/svg"><path fill="black" d="${RIGHT_TAIL_PATH}"/></svg>`)}`;
export const LEFT_TAIL_SVG = `data:image/svg+xml;base64,${btoa(`<svg width="3" height="3" xmlns="http://www.w3.org/2000/svg"><path fill="black" d="${LEFT_TAIL_PATH}"/></svg>`)}`;

// USER & BOT
export const USER = 'USER';
export const BOT = 'BOT';