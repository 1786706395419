import styled from '@emotion/styled';

const ChatBody = styled.div`
  overflow-y: auto;
  padding: 20px;
  scroll-behavior: smooth;
  grid-row: span 4 / span 4;
  background: transparent;

  
  &::-webkit-scrollbar {
    width: 3px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }
  &::-webkit-scrollbar-button{
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent; 
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme?.colors?.primary || '#FF136D'};
    border-radius: 20px;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme?.colors?.secondary || '#453FD6'};
  }

  /* Firefox scrollbar settings */
  scrollbar-width: thin;
  scrollbar-color: ${(props) =>
    `${props.theme?.colors?.primary || '#FF136D'} transparent`};
`;

export default ChatBody;
