//Services
import createApiService from "@/services";

export const botApi = async (apiUrl: string, id: string) => {
  const api = createApiService({
    baseUrl: `${apiUrl}/${id}`,
  });

  try{
    return await api.get("");
  } catch(error){
    console.error("Error in fetching bot details:", error);
    throw error;
  };
};