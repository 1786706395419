import React from 'react';
import ReactDOM from 'react-dom/client';
import Chatbot from './chatbot';

interface ChatbotConfig {
  id: string;
}

declare global {
  interface Window {
    Botric: {
      init: (config?: ChatbotConfig) => void;
    };
  }
}

function getBotIdFromScript(): string {
  const scripts = Array.from(document.getElementsByTagName('script'));

  for (const script of scripts) {
    if (script.src && (script.src.includes('index.umd.js') || script.src.includes('botric'))) {
      const botId = script.getAttribute('data-bot-id');
      if (botId) return botId;
    }
  }
  return '';
}

(function (global: Window) {
  global.Botric = {
    init: (config: ChatbotConfig = { id: '' }) => {
      if (!document.getElementById('botric-chatbot-container')) {
          const container = document.createElement('div');
          container.id = 'botric-chatbot-container';
          document.body.appendChild(container);
        const root = ReactDOM.createRoot(container);
        root.render(<Chatbot {...config} />);
      }
    },
  };

  const initialize = () => {
    const botId = getBotIdFromScript();
    if (botId) {
      global.Botric.init({ id: botId });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initialize);
  } else {
    initialize();
  }
})(window);
