const theme = {
  colors: {
    primary: '#607D8B', // Muted gray-blue for primary action
    secondary: '#455A64', // Slightly darker gray for secondary
    bgColor: '#263238', // Dark background
    foreground: '#FFFFFF', // White text
    header: '#37474F', // Darker gray for the header
  },
};

export default theme;

