import React, {ReactNode, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";

const ChatBotShadowDom = ({children}: { children: ReactNode }) => {
    const shadowRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(document.createElement("div"));
    const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null);
    const [cache, setCache] = useState<any>(null);

    useEffect(() => {
        if (shadowRef.current && !shadowRoot) {
            if (!shadowRef.current.shadowRoot) {
                const newShadowRoot = shadowRef.current.attachShadow({mode: "open"});
                setShadowRoot(newShadowRoot);

                const emotionCache = createCache({
                    key: "botric",
                    container: newShadowRoot,
                });
                setCache(emotionCache);

                newShadowRoot.appendChild(contentRef.current);
            }
        }
    }, [shadowRoot]);

    return (
        <div ref={shadowRef} id="botric-chatbot">
            {cache && shadowRoot
                ? (
                    <CacheProvider value={cache}>
                        {createPortal(children, contentRef.current)}
                    </CacheProvider>
                )
                : null}
        </div>
    );
};

export default ChatBotShadowDom;
