import React from 'react';
import styled from '@emotion/styled';

const SendButton = styled.button`
  background-color: #7e7f80;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    filter: brightness(90%)
  };
  &:disabled {
    cursor: not-allowed;
    filter: brightness(50%);
  };
`;

export default SendButton;
