import styled from "@emotion/styled";

const Timestamp = styled.span`
  font-size: 12px;
  /* color: #888; */
  margin-top: 4px;
  align-self: flex-end; 
  font-family: "Inter", sans-serif;
`;

export default Timestamp;
